import React, { useState, useEffect } from "react"
import styled from "styled-components"
import FridayShows from "./FridayShows"
import MondayShows from "./MondayShows"
import SaturdayShows from "./SaturdayShows"
import SundayShows from "./SundayShows"
import ThursdayShows from "./ThursdayShows"
import TuesdayShows from "./TuesdayShows"
import WednesdayShows from "./WednesdayShows"

const Container = styled.div`
  width: 100%;
  background-color: #6195bd;
  padding: 1rem 0;
  margin-top: 2rem;
  /* margin: 2rem 0; */
  .day-btns-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    border: 2px solid black;
    max-width: 1050px;
    margin: 0 auto;
    border-radius: 5px;
    overflow: hidden;

    @media (max-width: 1060px) {
      display: none;
    }
  }

  .selected-day {
    background-color: #be1e2d;
    color: white;
  }
  .unselected-day {
    color: black;
  }
`

const DayButton = styled.button`
  text-align: center;
  width: 150px;
  padding: 0.5rem;
  font-size: 1.2em;
  border: none;
  cursor: pointer;

  &:hover {
    background-color: #be1e2d;
    color: white;
  }
`

const SelcetContainer = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  display: none;

  @media (max-width: 1060px) {
    display: block;
  }
`

const StyledSelect = styled.select`
  width: 100%;
  padding: 1rem;
  font-size: 1.2em;
  text-align: center;
  margin: 0 auto;
`

const Schedule = () => {
  const [dayOfWeek, setDayOfWeek] = useState("")
  const [displayDay, setDisplayDay] = useState({
    monday: "",
    tuesday: "",
    wednesday: "",
    thursday: "",
    friday: "",
    saturday: "",
    sunday: "",
  })

  const setThursday = () => {
    if (dayOfWeek === 3) {
      document.getElementById("wednesday").setAttribute("selected", "selected")
    }
  }

  useEffect(() => {
    let day = new Date()
    let n = day.getDay()
    setDayOfWeek(n)
    setThursday()
  }, [])

  const handleSelect = e => {
    setDayOfWeek(parseInt(e.target.value))
  }

  return (
    <Container>
      <div className="day-btns-container">
        <DayButton
          onClick={() => setDayOfWeek(1)}
          style={{
            backgroundColor: dayOfWeek === 1 ? "#be1e2d" : "",
            color: dayOfWeek === 1 ? "white" : "",
          }}
        >
          Monday
        </DayButton>
        <DayButton
          onClick={() => setDayOfWeek(2)}
          style={{
            backgroundColor: dayOfWeek === 2 ? "#be1e2d" : "",
            color: dayOfWeek === 2 ? "white" : "",
          }}
        >
          Tuesday
        </DayButton>
        <DayButton
          onClick={() => setDayOfWeek(3)}
          style={{
            backgroundColor: dayOfWeek === 3 ? "#be1e2d" : "",
            color: dayOfWeek === 3 ? "white" : "",
          }}
        >
          Wednesday
        </DayButton>
        <DayButton
          onClick={() => setDayOfWeek(4)}
          style={{
            backgroundColor: dayOfWeek === 4 ? "#be1e2d" : "",
            color: dayOfWeek === 4 ? "white" : "",
          }}
        >
          Thursday
        </DayButton>
        <DayButton
          onClick={() => setDayOfWeek(5)}
          style={{
            backgroundColor: dayOfWeek === 5 ? "#be1e2d" : "",
            color: dayOfWeek === 5 ? "white" : "",
          }}
        >
          {" "}
          Friday
        </DayButton>
        <DayButton
          onClick={() => setDayOfWeek(6)}
          style={{
            backgroundColor: dayOfWeek === 6 ? "#be1e2d" : "",
            color: dayOfWeek === 6 ? "white" : "",
          }}
        >
          Saturday
        </DayButton>
        <DayButton
          onClick={() => setDayOfWeek(7)}
          style={{
            backgroundColor: dayOfWeek === 7 ? "#be1e2d" : "",
            color: dayOfWeek === 7 ? "white" : "",
          }}
        >
          Sunday
        </DayButton>
      </div>
      <SelcetContainer>
        <StyledSelect onChange={handleSelect} vlaue={dayOfWeek}>
          <option id="monday" value="1">
            Monday
          </option>
          <option id="tuesday" value="2">
            Tuesday
          </option>
          <option id="wednesday" value="3">
            Wednesday
          </option>
          <option id="thursday" value="4">
            Thursday
          </option>
          <option id="friday" value="5">
            Friday
          </option>
          <option id="saturday" value="6">
            Saturday
          </option>
          <option id="sunday" value="7">
            Sunday
          </option>
        </StyledSelect>
      </SelcetContainer>
      {dayOfWeek === 1 ? <MondayShows /> : ""}
      {dayOfWeek === 2 ? <TuesdayShows /> : ""}
      {dayOfWeek === 3 ? <WednesdayShows /> : ""}
      {dayOfWeek === 4 ? <ThursdayShows /> : ""}
      {dayOfWeek === 5 ? <FridayShows /> : ""}
      {dayOfWeek === 6 ? <SaturdayShows /> : ""}
      {dayOfWeek === 7 ? <SundayShows /> : ""}
    </Container>
  )
}

export default Schedule
