import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import styled from "styled-components"

const StyledContainer = styled.div`
  position: relative;
  width: 450px;
  height: 200px;
  border: 4px solid black;
  border-radius: 10px;
  overflow: hidden;
  margin: 3rem auto;
  cursor: pointer;

  @media (max-width: 490px) {
    width: 360px;
  }

  &:hover #description {
    display: block;
  }

  &:hover #overlay {
    display: block;
  }

  #img-container {
    width: 450px;
    height: 200px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  #title {
    position: absolute;
    top: 10px;
    left: 15px;
    font-family: "Orbitron", sans-serif;
    margin: 0;
    background-color: rgba(0, 0, 0, 0.7);
    padding: 0.5rem;
    color: white;
  }

  #description {
    display: none;
    position: absolute;
    top: 35px;
    padding: 0.5rem;
    font-family: "Roboto", sans-serif;
    color: whitesmoke;
  }

  #day {
    position: absolute;
    bottom: 10px;
    left: 15px;
    margin: 0;
    font-size: 1.2em;
    font-family: "Roboto", sans-serif;
    background-color: rgba(255, 255, 255, 0.7);
    padding: 0.3rem;
  }

  #time {
    position: absolute;
    bottom: 10px;
    right: 15px;
    margin: 0;
    font-size: 1.2em;
    font-family: "Roboto", sans-serif;
    background-color: rgba(255, 255, 255, 0.7);
    padding: 0.3rem;
  }

  #overlay {
    display: none;
    position: absolute;
    top: 0;
    background-color: rgba(0, 0, 0, 0.8);
    width: 100%;
    height: 100%;
  }
`

const TuesdayShows = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulPodcast(
        filter: { liveShow: { eq: true }, liveDayOfTheWeek: { eq: "Tuesday" } }
      ) {
        edges {
          node {
            title
            slug
            liveTimeOfDay
            liveDayOfTheWeek
            description
            albumArt {
              fluid {
                src
              }
            }
          }
        }
      }
    }
  `)

  return (
    <div>
      {data.allContentfulPodcast.edges.map(edge => {
        return (
          <Link to={`/podcast/${edge.node.slug}`}>
            <StyledContainer>
              <div id="overlay"></div>
              <div id="img-container">
                <img src={edge.node.albumArt.fluid.src} alt={edge.node.title} />
              </div>
              <h2 id="title">{edge.node.title}</h2>
              <p id="description">{edge.node.description}</p>
              <p id="day">{edge.node.liveDayOfTheWeek}'s</p>
              <p id="time">{edge.node.liveTimeOfDay}</p>
            </StyledContainer>
          </Link>
        )
      })}
    </div>
  )
}

export default TuesdayShows
